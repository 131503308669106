import React from 'react';
import { GridCol } from '@naf/grid';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../../redux/modules/membershipBenefitSearch';

interface Props {
  value: string;
}

export const SearchInput = ({ value }: Props) => {
  const dispatch = useDispatch();
  return (
    <GridCol s="12" m="12" l="12" xl="12">
      <StyledInput
        type="search"
        placeholder="Hva leter du etter?"
        value={value}
        onChange={(e) => {
          dispatch(actions.query({ query: e.target.value }));
        }}
      />
    </GridCol>
  );
};

const StyledInput = styled.input`
  width: 100%;
  height: 56px;
  border: 1px solid #c1c1c1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23767676' viewBox='0 0 24 24'%3E%3C!--! --%3E%3Cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3C/svg%3E");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-color: transparent;
  margin: 0;
  padding: 7px 8px 7px 44px;
  font-size: 1rem;
  color: inherit;
  border-radius: 2px;
`;
