import styled from 'styled-components';
import { AdvancedImage } from '@cloudinary/react';
import { spacing } from '@naf/theme';
import { Link } from 'react-router-dom';
import { Label } from '@naf/label';

export const Wrapper = styled(Link)`
  width: 254px;
  height: 345px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #c1c1c1;
  background: #fff;
  text-decoration: none;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border-bottom: 1px solid #c1c1c1;
  padding: ${spacing.space40} 0;
  position: relative;
`;

export const Description = styled.div`
  height: 100%;
  max-height: 100px;
  padding: ${spacing.space16};
`;

export const Footer = styled.div`
  padding: ${spacing.space24} ${spacing.space16};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  position: relative;
`;

export const PartnerLogo = styled.img`
  width: auto;
  height: ${spacing.space40};
`;

export const StyledAdvancedImage = styled(AdvancedImage)`
  width: auto;
  height: ${spacing.space40};
`;

export const TopLabelWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: ${spacing.space16};
`;

export const TopLabel = styled(Label)`
  display: flex;
  margin-right: ${spacing.space8};
`;

export const CardLabel = styled(Label)<{ $hasDiscount?: boolean }>`
  ${(props) => props.$hasDiscount && 'position: absolute;'};
  top: 0;
  left: ${spacing.space16};
  border: 1px solid #c1c1c1;
`;
