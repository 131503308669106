import React, { useEffect, useState } from 'react';
import { BehaviorSubject, debounceTime, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import qs from 'qs';

import { Grid } from '@naf/grid';
import { useDispatch } from 'react-redux';
import useSelector from '../../../../redux/typedHooks';
import { Filters } from './components/Filters';
import { Results } from './components/Results';
import { SearchInput } from './components/SearchInput';
import { MembershipBenefitSearchFilterType } from '../../../../../../types/MembershipBenefitSearchFilterType';
import { getURLParams } from '../../../../lib/getUrlParams';
import { actions } from '../../../../redux/modules/membershipBenefitSearch';
import { MembershipBenefitReference } from '../../../../../../types/membershipBenefitReferenceType';

interface BehaviorSubjectParams {
  query?: string;
  tag?: string;
  skip: number;
  tags?: string[];
  page: number;
  take: number;
}

export const MembershipBenefitListBlock = () => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);
  const searchState = useSelector((state) => state.membershipBenefitSearch);
  const dispatch = useDispatch();

  // Create the observable stream
  const [subject$] = useState<BehaviorSubject<BehaviorSubjectParams>>(
    new BehaviorSubject({
      query: undefined,
      tag: undefined,
      skip: 0,
      page: 1,
      take: 24,
      tags: [],
    }),
  );

  // Initial call to populate search
  useEffect(() => {
    if (searchState.isFirstRender) {
      const params: Record<string, any> = getURLParams();
      if (params.page) {
        params.take = parseInt(params.page, 10) * 24;
        params.page = parseInt(params.page, 10);
      }
      dispatch(actions.setState({ partialState: params }));
    }
  }, [dispatch, searchState.isFirstRender]);

  // Push new search state to observable stream for side effect processing
  useEffect(() => {
    if (subject$) {
      subject$.next({
        query: searchState.query,
        skip: searchState.skip,
        tags: searchState.tags,
        page: searchState.page,
        take: searchState.take,
      });
    }
    return () => {};
  }, [searchState.tags, searchState.query, searchState.page, searchState.skip, searchState.take, subject$]);

  // Subscribe to the observable stream and get new results based on last values
  useEffect(() => {
    if (apimBaseUrl && apimContentHub && apimNafNoApi) {
      const subscription = subject$
        .pipe(
          debounceTime(250),
          distinctUntilChanged(),
          switchMap((input) => {
            if (input) {
              const params: {
                SearchInput?: string;
                Tags?: MembershipBenefitSearchFilterType['slug'][];
                TagIds?: MembershipBenefitSearchFilterType['id'][];
                Skip?: number;
                Take?: number;
              } = {};
              if (input.query) {
                params.SearchInput = input.query;
              }
              if (input.tags) {
                params.Tags = input.tags;
              }
              if (input.skip) {
                params.Skip = input.skip;
              }
              if (input.take) {
                params.Take = input.take;
              }

              const queryString = qs.stringify(params, { arrayFormat: 'comma' });
              const oldUrlState = qs.parse(window?.location.search, { ignoreQueryPrefix: true });

              const urlStateString = qs.stringify(
                {
                  ...oldUrlState,
                  page: input.page,
                  tags: input.tags,
                  query: input.query,
                },
                { arrayFormat: 'comma' },
              );

              if (!window?.location.search) {
                window?.history.pushState({}, '', `${window?.location.pathname}?${urlStateString}`);
              } else {
                window?.history.replaceState({}, '', `${window?.location.pathname}?${urlStateString}`);
              }

              return ajax<{
                items: MembershipBenefitReference[];
                skip: number;
                total: number;
                countTotalItemsInTextSearch: number;
                availableTags: { title: string; count: number; id: string; slug: string }[];
              }>({
                method: 'get',
                url: `${apimBaseUrl}/${apimNafNoApi}/benefits?${queryString}`,
                headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
              }).pipe(
                map(({ response }) =>
                  dispatch(
                    actions.update({
                      items: response.items,
                      skip: response.skip,
                      total: response.total,
                      availableTags: response.availableTags,
                      countTotalItemsInTextSearch: response.countTotalItemsInTextSearch,
                    }),
                  ),
                ),
                catchError((err) =>
                  of(() => {
                    dispatch(actions.setError({ error: err }));
                  }),
                ),
              );
            }
            return of({});
          }),
        )
        .subscribe();

      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [subject$, apimBaseUrl, apimContentHub, apimNafNoApi, dispatch]);

  return (
    <Grid>
      <SearchInput value={searchState.query} />
      <Filters
        tags={searchState.tags}
        availableTags={searchState.availableTags}
        countTotalItemsInTextSearch={searchState.countTotalItemsInTextSearch}
      />
      <Results />
    </Grid>
  );
};
