import React from 'react';
import { useHistory } from 'react-router';
import { lazyload } from '@cloudinary/react';
import { Text, TextVariant } from '@naf/text';
import { Label } from '@naf/label';
import { IconButton } from '@naf/icon-button';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from '../../hooks/useCloudinary';
import { BenefitsType } from '../../../../types/benefitsType';
import { MembershipBenefitReference } from '../../../../types/membershipBenefitReferenceType';
import * as S from './Styles';

type Props = { benefit: BenefitsType | MembershipBenefitReference };

const BenefitCard = ({
  benefit: {
    image,
    title,
    listIngress,
    slug,
    isNewBenefit,
    isCampaign,
    hasActiveCampaign,
    partner,
    id,
    discountBadge,
    isLocalContent,
    serviceCategory,
    abTestExperimentSlug,
  },
}: Props) => {
  const itemSlug = slug || id;
  const history = useHistory();
  const cld = useCloudinary();
  const cldImage =
    image &&
    image.publicId &&
    cld.image(image.publicId).resize(fill().width(768).height(432)).quality('auto:eco').format('auto');
  const handleLocalPath = () => {
    if (history.location.pathname.includes('/fordeler'))
      return history.location.pathname.replace(/\/[^/]*$/, `/${slug}`);
    return `${history.location.pathname}/fordeler/${slug}`;
  };

  // Link to document
  const documentLink = isLocalContent ? handleLocalPath() : `/medlemskap/medlemsfordeler/${itemSlug}`;

  // Check whether the documentLink is overwritten by the document being part of an experiment
  // If it is, route the link via the experiment it is part of.
  const link = abTestExperimentSlug ? `/${abTestExperimentSlug}` : documentLink;

  return (
    <S.Wrapper to={link}>
      <S.Header>
        {partner?.logo && <S.PartnerLogo src={partner.logo.srcSet.jpgLarge} alt="Partnerlogo" />}
        {cldImage && !partner?.logo && (
          <S.StyledAdvancedImage
            alt={image?.altText || ''}
            cldImg={cldImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
          />
        )}
        <S.TopLabelWrapper>
          {isNewBenefit && <S.TopLabel variant="signature" text="nyhet" />}
          {isCampaign || (hasActiveCampaign && <S.TopLabel variant="moss" text="Kampanje" />)}
        </S.TopLabelWrapper>
      </S.Header>
      <S.Description>
        <Text variant={TextVariant.Header3}>{title}</Text>
        <Text variant={TextVariant.ArticleText}>{listIngress}</Text>
      </S.Description>
      <S.Footer>
        {serviceCategory?.cardLink && <S.CardLabel text="Kortrabatt" variant="white" $hasDiscount={!!discountBadge} />}
        <Label text={discountBadge} variant="white" />
        <IconButton variant="secondary" icon="arrow-right" onClick={() => {}} ariaLabel="gå til fordel" />
      </S.Footer>
    </S.Wrapper>
  );
};

export default BenefitCard;
