import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ResultPage } from './ResultPage';
import { actions } from '../../../../../redux/modules/membershipBenefitSearch';
import useSelector from '../../../../../redux/typedHooks';
import InfinteScroll from '../../../../infinite-scroll/InfinteScroll';

export const Results = () => {
  const dispatch = useDispatch();
  const {
    items: pages,
    isLoading,
    total,
    numberOfLoadedElements,
  } = useSelector((state) => state.membershipBenefitSearch);

  return (
    <InfinteScroll
      showLoadingTrigger={!isLoading && numberOfLoadedElements !== total}
      onTrigger={() => dispatch(actions.pagination({ take: 24 }))}
    >
      {Object.values(pages).length !== 0 ? (
        Object.entries(pages).map(([key, page]) => (
          <SubGrid key={key}>
            <ResultPage page={page} />
          </SubGrid>
        ))
      ) : (
        <SubGrid>
          <ResultPage page={{ data: [], meta: { isLoading: true } }} />
        </SubGrid>
      )}
    </InfinteScroll>
  );
};

const SubGrid = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  row-gap: 0;
  @media (min-width: 576px) {
    gap: 24px;
    row-gap: 0;
  }
  @media (min-width: 992px) {
    gap: 32px;
    row-gap: 0;
  }
`;
